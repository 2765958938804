<template>
	<div class="pui-form">
		<agenteaduana-modals :modelName="modelName"></agenteaduana-modals>
		<!--<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<agenteaduana-form-header :modelPk="modelPk"></agenteaduana-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout" xs12 md4>
				<v-flex xs12 md4>
					<pui-field-set highlighted>
						<v-layout wrap>
							<!-- NIFAGENTEADUANA -->
							<v-flex xs12 md6>
								<pui-text-field
									:id="`nifagenteaduana-agenteaduana`"
									v-model="model.nifagenteaduana"
									:label="$t('agenteaduana.nifagenteaduana')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="17"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NOMAGENTEADUANA -->
							<v-flex xs12>
								<pui-text-field
									:id="`nomagenteaduana-agenteaduana`"
									v-model="model.nomagenteaduana"
									:label="$t('agenteaduana.nomagenteaduana')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
				<v-flex xs12 md8>
					<pui-field-set :title="$t('agenteaduana.users')">
						<pui-multi-select
							id="multiSelectUser"
							itemValue="usr"
							itemText="name"
							v-model="model.users"
							:items="allUsers(model)"
							:itemsToSelect="model.users"
							:disabled="formDisabled"
							:key="refreshSelect"
						></pui-multi-select>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import agenteaduanaActions from './AgenteaduanaActions';
import agenteaduanaModals from './AgenteaduanaModals.vue';

export default {
	name: 'agenteaduana-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'agenteaduana-modals': agenteaduanaModals
	},
	data() {
		return {
			modelName: 'agenteaduana',
			actions: agenteaduanaActions.formActions,
			refreshSelect: 0
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		},
		allUsers(model) {
			if (model.allUsers) return model.allUsers;

			const params = {};

			const url = this.$store.getters.getModelByName(this.modelName).url.getNewAgente;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('agenteaduana.getNewAgente.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					model.allUsers = response.data.allusers;
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		}
	},
	created() {}
};
</script>
